<template>
  <div class="" :class="themeClass">
    <div class="">
      <div class="position-relative">
        <img src="../../assets/businessBanner.png" width="100%" />
        <div class="position-absolute absolute-top-content">
          <div class="businessBuy-top-item1">
            <div class="font-size56 font-color-fff font-weight700 margin-b-5 text-center">
              企业采购更专业
            </div>
            <div class="font-size30 font-color-fff text-center">
              省钱 省心 省时间
            </div>
            <div class="applyPrice margin-t-20 pointer flex-space-center">
              <div class="applyPriceBtn font-size30" @click="applicationPricetab()">
                申请议价
              </div>
            </div>
            <div class="top-tittle-box">
              <div class="top-tittle-box-content flex-row">
                <div class="flex-row top-tittle-div">
                  <div class="top-tittle-img">
                    <img src="../../assets/bussinessBuy/sp.png" width="100%" />
                  </div>
                  <div class="margin-l-13 pointer">
                    <div class="color-theme font-size32 text-center">
                      {{ goodsbusinessBuytotal }}
                    </div>
                    <div>企业签订商品</div>
                  </div>
                </div>
                <div class="flex-row top-tittle-div">
                  <div class="top-tittle-img">
                    <img src="../../assets/bussinessBuy/qy.png" width="100%" />
                  </div>
                  <div class="margin-l-13 pointer" @click="lookdetail">
                    <div class="color-theme font-size32 text-center">
                      {{ goodsbusinessBuyIng }}
                    </div>
                    <div>正在议价商品 <span class=" font-color-999 font-size12" style="display: inline-block;"></span></div>
                    <span class="look">查看更多></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="businessBuy-content">
      <div class="bargainingApplication" v-if="businessBuyData.length != 0">
        <div class="position-relative">
          <div class="tittle-bgc-text font-weight700">ENTERPRISE</div>
          <div class="title-l-img position-absolute">
            <img src="../../assets/bussinessBuy/title-l.png" />
          </div>
          <div class="title-r-img position-absolute">
            <img src="../../assets/bussinessBuy/title-r.png" />
          </div>
          <div class="position-absolute businessBuy-goods-text font-size24">
            企业购商品
          </div>
        </div>
      </div>
      <div v-loading="goodsLoading" v-if="businessBuyData.length != 0">
        <!-- <div class="flex-row-center-center" v-if="businessBuyData.length == 0">
					<svg-icon icon-class="NoContent" />
				</div> -->
        <div class="">
          <div class="businessBuy-Items pointer" v-for="(item1, index) in businessBuyData" :key="index"
            @click="clickBusinessGoods(item1.fGoodsID)">
            <div class="flex-row-center-center margin-t-10">
              <div class="flex-row-center-center bg-fff" style="width: 175px;height: 175px;">
                <div class="businessBuy-ItemsBox">
                  <img :src="item1.fPicturePath" width="100%" />
                </div>
              </div>
            </div>

            <div class="padding-14 font-size13">
              <div class="twoline-show-hide">
                {{ item1.fGoodsFullName }}
              </div>
              <div class="font-color-FF0033 margin-t-5">
                ¥<span class="font-size18 font-weight700">
                  <span v-if='item1.fMinPrice == item1.fMaxPrice'>{{ item1.fGoodsPrice }}</span>
                  <span v-else>{{item1.fMinPrice}}~{{item1.fMaxPrice}}</span>
                </span>
                元/吨
              </div>
              <div class="margin-top-2 font-size12 flex-row pointer">
                <div class="font-color-ccc margin-r-10 businessBuy-name hide-text">
                  {{ item1.fShopName }}
                </div>
                进店
                <div class="iconfont icon-arrow-down transform-rotate-negative-90 font-size20 margin-t-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="float: right;">
        <el-pagination @current-change="handleCurrentChange" :current-page="pagesStart" :page-size="pagesize"
          layout="prev, pager, next, jumper" :total="total" @prev-click="prevclick" @next-click="nextclick"
          :hide-on-single-page="true">
        </el-pagination>
      </div>
      <div style="clear: both;"></div>



      <div class="bargainingApplication">
        <div class="position-relative">
          <div class="tittle-bgc-text font-weight700 color-theme">
            BARGAINING
          </div>
          <div class="title-l-img position-absolute">
            <img src="../../assets/bussinessBuy/title-l.png" />
          </div>
          <div class="title-r-img position-absolute">
            <img src="../../assets/bussinessBuy/title-r.png" />
          </div>
          <div class="position-absolute font-size24 apply-price-text">
            议价申请
          </div>
        </div>
      </div>
    </div>
    <div class="yijia-content width-fill" v-loading="loading" style="width: 1125px">
      <div class="padding20-t-lr">
        <div v-if="list.length==0" class="flex-row-center-center position-relative">
          <div class="flex-row-center-center">
            <svg-icon icon-class="NoContent" />
          </div>
          <div class="goApply position-absolute text-center backgroundcolor-theme font-color-fff"
            @click="applicationPricetab()">去申请议价</div>
        </div>
        <div class="item margin-b-13" v-for="(item, index) in list" :key="index">
          <div class="border1-f2f4fd">
            <div class="item-title flex-row-space-between-center font-size12">
              <div class="flex-row">
                <div class="time padding-l-15" style="white-space:nowrap">
                  申请时间:&nbsp;{{ item.fCreateTime   }}
                </div>
                <div class="orderID">
                  询价单号:&nbsp;<span class="color-theme" style="white-space:nowrap">{{
                    item.fBillNumber
                  }}</span>
                </div>
                <div class="shopname flex-row-align-center">
                  <i class="iconfont icon-dianpu4 color-theme"></i>
                  <!-- <el-image style="width: 12px; height: 12px" :src="item.fShopLogoPath" :fit="fill"></el-image> -->
                  <div class="width-500 hide-text" :title="item.fShopName">
                    {{ item.fShopName }}
                  </div>
                </div>
                <div class="time padding-l-15" style="white-space:nowrap">
                  价格有效日期:&nbsp;{{ item.fBeginTime | zhDate }}
                  <span v-if="item.fEndTime != ''">~&nbsp;&nbsp;{{ item.fEndTime  | zhDate}}</span>
                </div>
              </div>
              <div class="operation flex-row-space-between-center margin-r-13">
                <div class="font-size12 backgroundcolor-theme bg-fff border-color-theme detailbtn font-color-fff"
                  style="margin-right: 10px"
                  v-if="item.fStatus == 1 && item.fApproveStatus == 254 && item.fCustomerApproveStatus == 0"
                  @click="agree(item.fInquiryApplyID)">
                  同意议价
                </div>
                <div class="font-size12 backgroundcolor-theme bg-fff border-color-theme detailbtn font-color-fff"
                  style="margin-right: 10px"
                  v-if="item.fStatus == 1 && item.fApproveStatus == 254 && item.fCustomerApproveStatus == 0"
                  @click="disagree(item.fInquiryApplyID)">
                  不同意议价
                </div>
                <div class="font-size12 backgroundcolor-theme bg-fff border-color-theme detailbtn font-color-fff"
                  style="margin-right: 10px" v-if="item.fStatus == 0 && item.fApproveStatus == 255"
                  @click="afreshBuyingPrice(item.fInquiryApplyID)">
                  重新议价
                </div>
                <div class="font-size12 color-theme bg-fff border-color-theme detailbtn"
                  @click="AuditBuyingPriceDetail(item.fInquiryApplyID)">
                  详情
                </div>
              </div>
            </div>
            <div class="item-content bg-fff flex-row-space-between-center">
              <div class="defaultHeight" :class="{ autoHeight: item.checked }">
                <div class="flex-row-align-center" v-for="(item1, index1) in item.details" :key="index1">
                  <div class="flex-row-space-between">
                    <div class="flex-row-align-center item-content-left">
                      <div class="goods-pic flex-row-align-center text-center">
                        <img :src="item1.fPicturePath" alt="" width="100%" />
                      </div>
                      <div>
                        <div class="productionPlant font-size13">
                          {{ item1.fGoodsFullName }}
                        </div>
                        <div sclass="flex-row ">
                          <div class="delivery color-theme border-color-theme text-center margin5-0 font-size12">
                            {{ item1.fDelivery }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item-content-right flex-row">
                      <div class="flex-row">
                        <!-- <div class="payment commonBox line-height90" :class="item1.fDetailStatus == 1 ? 'font-color-ae' : ''">
													{{ item1.fSaleAmount }}吨
												</div> -->
                        <div class="payment commonBox line-height23 border-1-r-f2f4fd" :class="[
                            item1.fDetailStatus == 0 ? 'color00CC0' : '',item1.fDetailStatus == 1 ? 'font-color-FF0033' : '',
                          ]">
                          <div class="margin25-auto">
                            <span class="font-size12"
                              v-if="item.fCustomerApproveStatusName =='同意'">{{item.fCustomerApproveStatusName}}</span>
                            <span class="font-size12" style="color: #333;"
                              v-if="item.fCustomerApproveStatusName =='不同意'">{{item.fCustomerApproveStatusName}}</span>
                            <span class="font-size12" style="color: #333;"
                              v-if="item.fApproveStatusName =='审核中'">{{item.fApproveStatusName}}</span>
                            <!-- <div class="font-weight700 font-size13" style="color: #333;">
															¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
														</div> -->
                            <!-- 已审核。同意 -->
                            <div class="font-weight700 font-size13"
                              v-if="item.fApproveStatusName == '已审核' &&item.fCustomerApproveStatusName == '同意'">

                              ¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
                            </div>
                            <!-- 已审核。不同意 -->
                            <div class="font-weight700 font-size13" style="color :#333"
                              v-if="item.fApproveStatusName == '已审核' &&item.fCustomerApproveStatusName == '不同意'">

                              ¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
                            </div>
                            <!-- 审核中 -->
                            <div class="font-weight700 font-size13" style="color :#333"
                              v-if="item.fApproveStatusName == '审核中'">

                              ¥{{ item1.fApplyGoodsPrice }}元/{{item1.fUnitName}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="clear: both" v-show="item.details.length > 2" class="margin-b-10">
            <div class="showBtn font-size12 text-center pointer" @click="showMore(index)" v-if="item.checked == false">
              展开 {{ item.details.length - 2 }} 件商品
            </div>
            <div class="showBtn font-size12 text-center pointer" @click="showMore(index)" v-if="item.checked">
              收起列表
            </div>
          </div>
        </div>
        <div class="text-center lookdetail-yijia" v-if="list.length >= 4">
          <span @click="lookdetail" class="font-color-FF9900">查看全部议价</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters
  } from "vuex";
  export default {
    data() {
      return {
        fill: "cover",
        maxResultCount: 5,
        page: 1,
        list: [],
        goodsbusinessBuytotal: "", //企业签订商品
        goodsbusinessBuyIng: "", //正在议价商品
        businessBuyData: [],
        loading: false,
        goodsLoading: false,
        pagesize: 10, //每页数量
        pagenowsize: 0, //当前的页数数字
        total: 0, //总条目数量
        pagesStart: 1, //页面当前页数
      };
    },
    computed: {
      ...mapGetters(["getThemeName"]),
      themeClass() {
        return `theme-${this.getThemeName}`;
      },
    },
    mounted() {
      this.inintgetgoodsNum(); //企业签订商品数量
      this.getgoodsList(); //企业购的商品展示
      this.getgoodsbusinessBuyIng(); //正在议价商品数量
      this.getbuyingPricelist(); //全部议价
    },
    activated() {
      this.getgoodsbusinessBuyIng(); //正在议价商品数量
      this.getbuyingPricelist(); //全部议价
      this.getgoodsList(); //企业购的商品展示
      this.inintgetgoodsNum(); //企业签订商品数量
    },
    filters: {
      zhDate(date) {
        if (date != null) {
          return date.replace(/(.+?)\-(.+?)\-(.+)/, "$1年$2月$3日")
        }
        // str.replace(/(.+?)\-(.+?)\-(.+)/,"$1年$2月$3日")
      }
    },
    methods: {
      showMore(index) {
        this.list.forEach((item, index1) => {
          if (index == index1) {
            item.checked = !item.checked;
          }
        });
      },
      prevclick() {
        this.pagesStart = this.pagesStart - 1;
        this.getgoodsList();
      },
      nextclick() {
        this.pagesStart = this.pagesStart + 1;
        this.getgoodsList();
      },
      handleCurrentChange(e) {
        this.pagesStart = e
        this.getgoodsList();
      },

      inintgetgoodsNum() {
        this.ApiRequestPostNOMess(
          "/api/mall/ebsale/b2b-inquiry-apply-goods-price/get-customer-b2b-goods-count", {}
        ).then(
          (res) => {
            this.goodsbusinessBuytotal = res.obj;
          },
          (error) => {}
        );
      },
      getgoodsbusinessBuyIng() {
        this.ApiRequestPostNOMess(
          "/api/mall/ebsale/b2b-inquiry-apply/get-audit-count", {}
        ).then(
          (res) => {
            this.goodsbusinessBuyIng = res.obj;
          },
          (error) => {}
        );
      },
      getgoodsList() {
        this.goodsLoading = true;
        console.log('发到服务器')
        console.log(this.pagesStart * this.pagesize)
        console.log('发到服务器')
        this.ApiRequestPost(
          "api/mall/ebsale/b2b-inquiry-apply-goods-price/get-new-customer-b2b-goods-list", {
            maxResultCount: this.pagesize,
            filter: "",
            sorting: "",
            skipCount: (this.pagesStart - 1) * this.pagesize,
          }
        ).then(
          (res) => {
            this.goodsLoading = false;
            console.log(res.obj, "企业购商品");
            this.total = res.obj.totalCount;
            this.businessBuyData = res.obj.items;
          },
          (error) => {}
        );
      },
      getbuyingPricelist() {
        this.loading = true;
        this.ApiRequestPost("api/mall/ebsale/b2b-inquiry-apply/get-list", {
          filter: "",
          maxResultCount: this.maxResultCount,
        }).then(
          (res) => {
            console.log("获取全部议价的列表2", res.obj);
            for (let item of res.obj.items) {
              item.checked = false;
              item.fCreateTime = this.getTime(item.fCreateTime);
              item.fModifyTime = this.getTime(item.fModifyTime);
              item.fEndTime = this.getDate(item.fEndTime);
              item.fBeginTime = this.getDate(item.fBeginTime);
            }
            this.list = res.obj.items;
            console.log(this.list, '全部议价');
            this.loading = false;
            // console.log(this.list, "890");
          },
          (error) => {}
        );
      },
      lookdetail() {
        this.$router.push("./buyingPricelist");
        // alert(1);
      },
      AuditBuyingPriceDetail(detailid) {
        this.$router.push({
          name: "buyingPriceDetails",
          params: {
            id: detailid,
          },
        });
      },
      applicationPricetab() {
        this.$router.push({
          name: "applicationPricetab",
          query: {
            edit: false,
          },
        });
        // this.$router.push("./applicationPricetab");
      },
      clickBusinessGoods(id) {
        this.$router.push({
          name: "GoodsDetailsB2B",
          query: {
            fGoodsID: id,
          },
        });
      },
      //客户同意议价结果
      agree(aid) {
        this.loading = true;
        this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/confirm", {
          fInquiryApplyID: aid
        }).then(
          (res) => {
            this.getbuyingPricelist(); //全部议价
            this.inintgetgoodsNum();
            this.getgoodsbusinessBuyIng(); //正在议价商品数量
            this.getgoodsList();
          },
          (error) => {}
        );
      },
      //客户不同意议价结果
      disagree(aid) {
        this.loading = true;
        this.ApiRequestPut("api/mall/ebsale/b2b-inquiry-apply/disagree", {
          fInquiryApplyID: aid
        }).then(
          (res) => {
            this.getbuyingPricelist(); //全部议价
            this.inintgetgoodsNum();
            this.getgoodsbusinessBuyIng(); //正在议价商品数量
          },
          (error) => {}
        );
      },
      //重新议价
      afreshBuyingPrice(aid) {
        this.$router.push({
          name: "applicationPricetab",
          query: {
            id: aid,
            edit: true,
          },
        });
      },
      // buyPriceDetails(id) {
      //   this.$router.push({
      //     path: "./buyingPriceDetails",
      //     query: {
      //       id: id,
      //     },
      //   });
      // },
    },
  };
</script>
<style scoped lang="scss">
  .border-1-r-f2f4fd {
    border-right: 1px solid #f2f4fd;
  }

  .color00CC0 {
    color: #00cc00;
  }

  .margin-r-10 {
    margin-right: 10px;
  }

  .businessBuy-top-box {
    width: 1100px;
    margin: 0 auto;
    margin-top: -240px;
  }

  .businessBuy-top-item1 {
    width: 820px;
    margin: 0 auto;
  }

  .absolute-top-content {
    left: 28.7%;
    top: 19.5%;
  }

  .line-height46 {
    line-height: 46px;
  }

  .applyPriceBtn {
    width: 290px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #ffffff;
    margin-bottom: 100px;
    background: linear-gradient(to right, #233FC7, #4B80E3, #7C8EEB);
    border: 2px solid #2871FA;
    border-radius: 30px;
    box-shadow: 0 10px 20px 0 rgba(6, 24, 151, 0.65);
  }

  .showBtn {
    width: 112px;
    height: 29px;
    line-height: 29px;
    background-color: rgba(227, 232, 251, 1);
    border-radius: 0 0 10px 10px;
    color: #6980e7;
    margin: 0 auto;
  }

  .look {
    position: absolute;
    bottom: 19px;
    color: #0173FE;
    right: 90px;
    font-size: 12px;
  }

  .top-tittle-box {
    height: 90px;
    width: 100%;
    box-shadow: 0px 5px 5px 0px rgba(204, 204, 204, 0.2);
    background-color: #ffffff;

    .top-tittle-box-content {
      width: 80%;
      margin: 0 auto;
      padding: 10px 0;

      .top-tittle-div {
        width: 50%;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        // transition: 0.2s;
      }

      // .top-tittle-div:hover{
      // 	font-size: 20px;
      // }
    }
  }

  .top-tittle-img {
    width: 47px;
    height: 47px;
    margin-top: 10px;
    overflow: hidden;
  }

  .Buytopbox {
    position: relative;
  }

  .businessBuy-tittle-text {
    width: 200px;
    margin: 12px auto;
    font-size: 26px;
    z-index: 999;
    letter-spacing: 1px;
    line-height: 80px;
  }

  .businessBuy-topbgc {
    width: 1100px;
    height: 233px;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    z-index: 18;
  }

  .businessBuy-top1div {
    border-radius: 10px;
    position: absolute;
    left: 10px;
    top: 4%;
    width: 1080px;
    height: 212px;
    opacity: 0.6;
    z-index: 208;

    @include themify($themes) {
      background-color: themed("themes_color");
    }
  }

  .businessBuy-top2div {
    width: 1057px;
    height: 82px;
    opacity: 0.1;
    background: #ffffff;

    z-index: 290;
  }

  .businessBuy-tittleBox {
    margin-top: 10px;
    width: 1100px;
    height: 90px;
    background: #2386fe;
    border-radius: 10px;
  }

  .businessBuy-my-textbox {
    position: absolute;
    left: 21%;
    top: 10%;
    z-index: 99999;
  }

  .padding20-t-lr {
    padding: 20px 20px 0 20px;
  }

  .margin25-auto {
    margin: 25px auto;
  }

  .bargainingApplication {
    width: 326px;
    margin: 15px auto;
    text-align: center;

    .tittle-bgc-text {
      width: 326px;
      opacity: 0.05;
      font-size: 48px;
      font-weight: bold;
      text-align: center;
    }

    .title-l-img {
      left: 87px;
      top: 8px;
    }

    .title-r-img {
      right: 74px;
      bottom: 0;
    }

    .businessBuy-goods-text {
      right: 100px;
      top: 18px;
    }

    .apply-price-text {
      right: 116px;
      top: 18px;
    }
  }

  .yijia-content {
    width: 1100px;
    background: #f8f9fe;
    margin: 0 auto 42px auto;
  }

  .color-theme {
    @include themify($themes) {
      color: themed("themes_color");
    }
  }

  .border-color-theme {
    @include themify($themes) {
      border: 1px solid themed("themes_color");
    }
  }

  .backgroundcolor-theme {
    @include themify($themes) {
      background-color: themed("themes_color");
    }
  }

  .font-color-FF0033 {
    color: #ff0033;
  }

  .margin5-0 {
    margin: 5px 0px;
  }

  .padding-14 {
    padding: 14px;
  }

  .item-title {
    box-sizing: border-box;
    height: 35px;
    line-height: 35px;
    background: #f2f4fd;

    .orderID {
      margin-left: 15px;
    }

    .shopname {
      height: 35px;
      margin-left: 13px;

      .e-image {
        margin-left: 31px;
        margin-right: 5px;
      }

      .width-500 {
        padding-left: 5px;
        width: 150px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .defaultHeight {
    max-height: 180px;
    overflow: hidden;
  }

  .autoHeight {
    max-height: 100% !important;
    overflow: hidden;
  }

  .item-content {
    .item-content-left {
      width: 936px;
      height: 90px;
    }

    .goods-pic {
      width: 65px;
      height: 65px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
      margin-right: 19px;
      margin-left: 15px;
      overflow: hidden;
    }

    .productionPlant {
      height: 16px;
      line-height: 16px;
      margin-top: 8px;
      margin-bottom: 5px;
    }

    .delivery {
      width: max-content;
      padding: 0 5px;
      height: 15px;
      line-height: 15px;
    }

    .engineering {
      color: #aeaeae;
    }
  }

  .detailbtn {
    width: 71px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    text-align: center;
    margin: 0 auto;
  }

  .line-height23 {
    line-height: 23px;
  }

  .line-height90 {
    line-height: 90px;
  }

  .commonBox {
    width: 123px;
    height: 90px;
    text-align: center;
    border-left: 1px solid #f2f4fd;
  }

  .lookdetail-yijia {
    padding: 6px 0 20px 0;
  }

  .border1-f2f4fd {
    border: 1px solid #f2f4fd;
  }

  .detailbtn:hover,
  .lookdetail-yijia:hover {
    cursor: pointer;
  }

  .businessBuy-content {
    width: 1100px;
    height: auto;
    margin: 50px auto 0 auto;
    overflow: hidden;

    .businessBuy-Items {
      width: 197px;
      height: 298px;
      background: #f1f1fa;
      margin: 0 23px 0 0;
      margin-top: 10px;
      vertical-align: middle;
      display: inline-block;


      .businessBuy-ItemsBox {
        width: 114px;
        height: 151px;
        // margin: 13px 14px 0 14px;
      }
    }
  }

  .businessBuy-name {
    max-width: 110px;
  }

  .my-buy {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .top-text-center {
    margin: 0 120px;
    line-height: 35px;
    position: relative;
  }

  .weight-dun {
    height: 35px;
    line-height: 45px;
  }

  .weight-fang {
    height: 35px;
    line-height: 45px;
  }

  .transform-rotate-negative-90 {
    transform: rotate(-90deg);
  }

  .margin-t-1 {
    margin-top: -2px;
  }

  .margin-top-2 {
    margin-top: 2px;
  }

  .goApply {
    width: 235px;
    height: 45px;
    line-height: 45px;
    bottom: 20px;
  }
</style>